import React, { useState } from 'react';
import Courses from './Courses';
import CreateCourse from './CreateCourse';

const Dashboard = ({ auth }) => {
  const [view, setView] = useState('courses');

  return (
    <div className="container-fluid">
      <div className="row">
        <nav className="col-md-2 d-none d-md-block bg-light sidebar">
          <div className="sidebar-sticky">
            <ul className="nav flex-column">
              <li className="nav-item">
                <span className="nav-link" onClick={() => setView('courses')}>Courses</span>
              </li>
              <li className="nav-item">
                <span className="nav-link" onClick={() => setView('create')}>Create Course</span>
              </li>
            </ul>
          </div>
        </nav>

        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
          {view === 'courses' ? <Courses auth={auth} /> : <CreateCourse auth={auth} />}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
