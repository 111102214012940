import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditRegistration = ({ auth }) => {
  const { RegID } = useParams(); // Get RegID from URL params
  const [RegData, setRegData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Loading state to show a spinner if needed
  const navigate = useNavigate();

  // Function to fetch the course data by course ID
  const fetchRegData = async () => {
    try {
      const response = await axios.get(`https://thakurcapital.com/Courses/API/ByID/Registrations/${RegID}`, {
        auth: {
          username: auth.email,
          password: auth.password,
        },
      });
      setRegData(response.data.Data[0]); // API returns course data under 'Data' array
      setLoading(false);
      setError('');
    } catch (err) {
      setError('Failed to fetch data.');
      setLoading(false);
    }
  };

  // Fetch the course data when the component mounts
  useEffect(() => {
    fetchRegData();
  }, [RegID, auth]);

  // Handle form submission to update the course
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedRegData = {
      Data: [RegData], // Wrap RegData in the 'Data' array as required by the API
    };

    try {
      await axios.post('https://thakurcapital.com/Courses/API/Update/Registrations', updatedRegData, {
        auth: {
          username: auth.email,
          password: auth.password,
        },
      });
      navigate('/dashboard'); // Navigate back to the dashboard after successful update
    } catch (err) {
      setError('Failed to update Data.');
    }
  };

  // Handle changes to form fields
  const handleChange = (e) => {
    setRegData({
      ...RegData,
      [e.target.name]: e.target.value,
    });
  };

  if (loading) {
    return <p>Loading data...</p>;
  }

  return (
    <div className="container">
      <h2>Edit Registrations</h2>
      {error && <p className="text-danger">{error}</p>}

      {RegData && (
        <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">First Name</label>
          <input
            type="text"
            className="form-control"
            name="Fname"
            value={RegData.Fname || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Middle Name</label>
          <input
            type="text"
            className="form-control"
            name="Mname"
            value={RegData.Mname || ''}
            onChange={handleChange}
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Last Name</label>
          <input
            type="text"
            className="form-control"
            name="Lname"
            value={RegData.Lname || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Course ID</label>
          <input
            type="text"
            className="form-control"
            name="CourceID"
            value={RegData.CourceID || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Contact Number</label>
          <input
            type="text"
            className="form-control"
            name="ContactNo"
            value={RegData.ContactNo || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Alternate Number</label>
          <input
            type="text"
            className="form-control"
            name="AlternateNo"
            value={RegData.AlternateNo || ''}
            onChange={handleChange}
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">State</label>
          <input
            type="text"
            className="form-control"
            name="State"
            value={RegData.State || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">City</label>
          <input
            type="text"
            className="form-control"
            name="City"
            value={RegData.City || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Pincode</label>
          <input
            type="text"
            className="form-control"
            name="Pincode"
            value={RegData.Pincode || ''}
            onChange={handleChange}
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Address</label>
          <input
            type="text"
            className="form-control"
            name="Address"
            value={RegData.Address || ''}
            onChange={handleChange}
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Payment Mode</label>
          <input
            type="text"
            className="form-control"
            name="PaymentMode"
            value={RegData.PaymentMode || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Payment Status</label>
          <input
            type="text"
            className="form-control"
            name="PaymentStatus"
            value={RegData.PaymentStatus || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Fees</label>
          <input
            type="number"
            className="form-control"
            name="Fees"
            value={RegData.Fees || ''}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Email ID</label>
          <input
            type="email"
            className="form-control"
            name="EmailID"
            value={RegData.EmailID || ''}
            onChange={handleChange}
          />
        </div>
      
        <div className="mb-3">
          <label className="form-label">Transaction ID</label>
          <input
            type="text"
            className="form-control"
            name="TransactionID"
            value={RegData.TransactionID || ''}
            onChange={handleChange}
          />
        </div>
      
        <button type="submit" className="btn btn-primary">
          Save Changes
        </button>
      </form>
      
      )}
    </div>
  );
};

export default EditRegistration;
