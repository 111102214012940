import React, { useState } from 'react';
import axios from 'axios';

const CreateCourse = ({ auth }) => {
  const [course, setCourse] = useState({
    CourseID: 'New',
    CourseTitle: '',
    CourseSubTitle: '',
    CourseType: '',
    CourseDesc: '',
    CourseDate: '',
    CourseLocation: '',
    CourseBy: '',
    Language: '',
    Fees: '',
    Time: ''
  });

  const handleChange = (e) => {
    setCourse({ ...course, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://thakurcapital.com/Courses/API/Update/Courses', 
        { Data: [course] }, 
        {
          auth: {
            username: auth.email,
            password: auth.password
          }
        }
      );
      alert('Course Created Successfully!');
    } catch (err) {
      alert('Error creating course');
    }
  };

  return (
    <div>
      <h2>Create New Course</h2>
      <form onSubmit={handleSubmit}>
    
        <div className="mb-3">
        <label>Course ID</label>
        <input type="text" name="CourseID"  value={course.CourseID} className="form-control" disabled />
      </div>

      {/* Course Title */}
      <div className="mb-3">
        <label>Course Title</label>
        <input type="text" name="CourseTitle" className="form-control" onChange={handleChange} required />
      </div>

      {/* Course SubTitle */}
      <div className="mb-3">
        <label>Course SubTitle</label>
        <input type="text" name="CourseSubTitle" className="form-control" onChange={handleChange} required />
      </div>

      {/* Course Type - Dropdown */}
      <div className="mb-3">
        <label>Course Type</label>
        <select name="CourseType" className="form-select" onChange={handleChange} required>
          <option value="">Select Course Type</option>
          <option value="Level 0">Level 0</option>
          <option value="Level 1">Level 1</option>
          <option value="Level 2">Level 2</option>
          <option value="Level 3">Level 3</option>
          <option value="Level 4">Level 4</option>
        </select>
      </div>

      {/* Course Description */}
      <div className="mb-3">
        <label>Course Description</label>
        <input type="text" name="CourseDesc" className="form-control" onChange={handleChange} required />
      </div>

      {/* Course Date */}
      <div className="mb-3">
        <label>Course Date</label>
        <input type="date" name="CourseDate" className="form-control" onChange={handleChange} required />
      </div>

      {/* Course Location */}
      <div className="mb-3">
        <label>Course Location</label>
        <input type="text" name="CourseLocation" className="form-control" onChange={handleChange} required />
      </div>

      {/* Course By */}
      <div className="mb-3">
        <label>Course By</label>
        <input type="text" name="CourseBy" className="form-control" onChange={handleChange} required />
      </div>

      {/* Language */}
      <div className="mb-3">
        <label>Language</label>
        <input type="text" name="Language" className="form-control" onChange={handleChange} required />
      </div>

      {/* Fees */}
      <div className="mb-3">
        <label>Fees</label>
        <input type="number" name="Fees" className="form-control" onChange={handleChange} required />
      </div>

    

        {/* Other form fields */}
        <button type="submit" className="btn btn-primary">Create Course</button>
      </form>
    </div>
  );
};

export default CreateCourse;
