import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditCourse = ({ auth }) => {
  const { courseId } = useParams(); // Get courseId from URL params
  const [courseData, setCourseData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Loading state to show a spinner if needed
  const navigate = useNavigate();

  // Function to fetch the course data by course ID
  const fetchCourseData = async () => {
    try {
      const response = await axios.get(`https://thakurcapital.com/Courses/API/ByID/Courses/${courseId}`, {
        auth: {
          username: auth.email,
          password: auth.password,
        },
      });
      setCourseData(response.data.Data[0]); // API returns course data under 'Data' array
      setLoading(false);
      setError('');
    } catch (err) {
      setError('Failed to fetch course data.');
      setLoading(false);
    }
  };

  // Fetch the course data when the component mounts
  useEffect(() => {
    fetchCourseData();
  }, [courseId, auth]);

  // Handle form submission to update the course
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedCourseData = {
      Data: [courseData], // Wrap courseData in the 'Data' array as required by the API
    };

    try {
      await axios.post('https://thakurcapital.com/Courses/API/Update/Courses', updatedCourseData, {
        auth: {
          username: auth.email,
          password: auth.password,
        },
      });
      navigate('/dashboard'); // Navigate back to the dashboard after successful update
    } catch (err) {
      setError('Failed to update course.');
    }
  };

  // Handle changes to form fields
  const handleChange = (e) => {
    setCourseData({
      ...courseData,
      [e.target.name]: e.target.value,
    });
  };

  if (loading) {
    return <p>Loading course data...</p>;
  }

  return (
    <div className="container">
      <h2>Edit Course</h2>
      {error && <p className="text-danger">{error}</p>}

      {courseData && (
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Course Title</label>
            <input
              type="text"
              className="form-control"
              name="CourseTitle"
              value={courseData.CourseTitle || ''}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Subtitle</label>
            <input
              type="text"
              className="form-control"
              name="CourseSubTitle"
              value={courseData.CourseSubTitle || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Type</label>
            <input
              type="text"
              className="form-control"
              name="CourseType"
              value={courseData.CourseType || ''}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Description</label>
            <input
              type="text"
              className="form-control"
              name="CourseDesc"
              value={courseData.CourseDesc || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Date</label>
            <input
              type="date"
              className="form-control"
              name="CourseDate"
              value={courseData.CourseDate || ''}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Location</label>
            <input
              type="text"
              className="form-control"
              name="CourseLocation"
              value={courseData.CourseLocation || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Instructor</label>
            <input
              type="text"
              className="form-control"
              name="CourseBy"
              value={courseData.CourseBy || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Language</label>
            <input
              type="text"
              className="form-control"
              name="Language"
              value={courseData.Language || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Fees</label>
            <input
              type="number"
              className="form-control"
              name="Fees"
              value={courseData.Fees || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Time</label>
            <input
              type="text"
              className="form-control"
              name="Time"
              value={courseData.Time || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Completed</label>
            <input
              type="text"
              className="form-control"
              name="Completed"
              value={courseData.Completed || ''}
              onChange={handleChange}
            />
          </div>

          

          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </form>
      )}
    </div>
  );
};

export default EditCourse;
