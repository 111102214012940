import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Registrations = ({ auth }) => {
  const { courseId } = useParams(); // Get courseId from URL params
  const [registrations, setRegistrations] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleEditClick = (courseId) => {
    navigate(`/edit-reg/${courseId}`);
  };

  // Function to fetch registrations by course ID
  const fetchRegistrations = async () => {
    try {
      const response = await axios.get(`https://thakurcapital.com/Courses/API/ByCourse/Registrations/${courseId}`, {
        auth: {
          username: auth.email,
          password: auth.password,
        },
      });
      setRegistrations(response.data.Data); // Assuming 'Registrations' is part of the course data
      setLoading(false);
      setError('');
    } catch (err) {
      setError('Failed to fetch registrations.');
      setLoading(false);
    }
  };

  // Fetch registrations when the component mounts
  useEffect(() => {
    fetchRegistrations();
  }, [courseId, auth]);

  if (loading) {
    return <p>Loading registrations...</p>;
  }

  return (
    <div>
      <h2>Registrations for Course ID: {courseId}</h2>
      {error && <p className="text-danger">{error}</p>}
      <table className="table">
        <thead>
          <tr>
            <th>RegID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact Number</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {registrations.map((registration) => (
            <tr key={registration.RegID}>
              <td>{registration.RegID}</td>
              <td>{registration.Fname} {registration.Lname}</td>
              <td>{registration.EmailID}</td>
              <td>{registration.ContactNo}</td>
              <td>{registration.PaymentStatus}</td>
              <td><button className="btn btn-sm btn-warning" onClick={() => handleEditClick(registration.RegID)}>Edit</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Registrations;
