import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Courses = ({ auth }) => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('All');
  const navigate = useNavigate();

  const handleEditClick = (courseId) => {
    navigate(`/edit-course/${courseId}`);
  };

  const handleRegistrationsClick = (courseId) => {
    navigate(`/registrations/${courseId}`);
  };

  // Function to fetch courses based on the selected type
  const fetchCourses = async (level) => {
    let apiUrl = 'https://thakurcapital.com/Courses/API/All/Courses'; // Default for 'All'

    if (level !== 'All') {
      apiUrl = `https://thakurcapital.com/Courses/API/ByType/Courses/${level}/All`;
    }

    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: auth.email,
          password: auth.password,
        },
      });
      setCourses(response.data.Data);
      setError('');
    } catch (err) {
      setError('Failed to fetch courses.');
    }
  };

  // Initial course fetch
  useEffect(() => {
    fetchCourses('All'); // Fetch all courses initially
  }, [auth]);

  // Handle dropdown change and fetch courses based on level
  const handleLevelChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedLevel(selectedValue);
    fetchCourses(selectedValue);
  };


  return (
    <div>
      <h2>Courses</h2>
      <div className="d-flex justify-content-between mb-3">
       

        <div className="dropdown">
          <select
            className="form-select"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            <option value="All">All</option>
            <option value="Level 0">Level 0</option>
            <option value="Level 1">Level 1</option>
            <option value="Level 2">Level 2</option>
            <option value="Level 3">Level 3</option>
            <option value="Level 4">Level 4</option>
          </select>
        </div>
      </div>


      {error && <p className="text-danger">{error}</p>}
      <table className="table">
        <thead>
          <tr>
          <th>Course ID</th>
            <th>Course Title</th>
            <th>Subtitle</th>
            <th>Type</th>
            <th>Date</th>
            <th>Location</th>
            <th>Registrations</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course.CourseID}>
              <td>{course.CourseID}</td>
              <td>{course.CourseTitle}</td>
              <td>{course.CourseSubTitle}</td>
              <td>{course.CourseType}</td>
              <td>{course.CourseDate}</td>
              <td>{course.CourseLocation}</td>
              <td><button className="btn btn-sm btn-info" onClick={() => handleRegistrationsClick(course.CourseID)}>{course.Count}</button></td>
              <td><button className="btn btn-sm btn-warning" onClick={() => handleEditClick(course.CourseID)}>Edit</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Courses;
