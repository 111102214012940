import logo from './logo.svg';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import EditCourse from './components/EditCourse';
import Registrations from './components/Registrations';
import EditRegistration from './components/EditRegistration';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [auth, setAuth] = useState({ email: '', password: '' });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} setAuth={setAuth} />} />
        <Route
          path="/dashboard"
          element={isLoggedIn ? <Dashboard auth={auth} /> : <Navigate to="/" />}
        />
         <Route
          path="/edit-course/:courseId"
          element={isLoggedIn ? <EditCourse auth={auth} /> : <Navigate to="/" />}
        />

        <Route path="/registrations/:courseId" element={isLoggedIn ? <Registrations auth={auth} /> : <Navigate to="/" />} />
        <Route path="/edit-reg/:RegID" element={isLoggedIn ? <EditRegistration auth={auth} /> : <Navigate to="/" />} />

      </Routes>
    </Router>
  );
}

export default App;